import React, { useEffect } from 'react'
import { HOME } from 'routes'
import { Link } from 'react-router-dom'
import 'views/blog/style.scss'
import 'components/nav/style.scss'
import { images } from 'assets'

const _20210427: React.FC = () => {
  useEffect(() => {
    document.title = 'Blog · Matt Morrison'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="nav">
        <Link to={HOME} style={{ color: 'white', textDecoration: 'none', fontWeight: 600 }}>
          &#8592; Back To Home
        </Link>
      </div>
      <div className="blog-content">
        <h3>The Construction Industry - An Outsider Perspective</h3>
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4>Published 29th April 2021</h4>
        </div> */}
        <i>
          As someone relatively new to the Construction industry (~3 years), I am still amazed (and perplexed) at some
          of the problems the industry is facing and the lack of attention they receive.
          <br />
          <br /> While people love talking about the exciting stuff - Artificial Intelligence, Virtual Reality, Digital
          Twins, Blockchain - catastrophic errors are caused in projects when the basics are still not being done right.
        </i>
        <br />
        <br />
        <h4>Some context - who am I and why am I writing this?</h4>
        <p>
          {' '}
          I was introduced to the Construction sector at the end of 2018 when I joined{' '}
          <a href="https://www.joinef.com/" target="_blank" rel="noopener noreferrer">
            Entrepreneur First
          </a>{' '}
          in London. I was amazed that one of the largest industries in the world had not enjoyed an increase in
          productivity for decades. I remember thinking - &quot;This surely isn&apos;t right? This must be a huge
          opportunity?&quot; I was sold.
        </p>
        <p>
          {' '}
          My co-founder and I immediately jumped into working on smart contracts that validated BIM models against
          specifications and created a tamper-proof audit trail of all model contributions.
        </p>
        <p>
          From a technical perspective, I was proud - mixing a private blockchain implementation (
          <a href="https://consensys.net/quorum/" target="_blank" rel="noopener noreferrer">
            Quorum
          </a>
          ) with a NoSQL database, all through a simple frontend built in React + Typescript. However, an interesting
          thing happened.
        </p>
        <p>No one cared.</p>
        <p>
          There were so many simple problems still to fix in Construction that the thought of using Blockchain was
          completely farfetched. However, three years later, those simple problems have still not been fixed. Even more
          worryingly, there seems to be a severe lack of conversation around these simple problems and a lack of
          willingness to try new products and platforms to move the industry forward.
        </p>
        <br />

        <h4>Thought #1 - spend time thinking about the simple problems</h4>
        <p>
          As humans, we want to think about exciting stuff. It is far more interesting to think about an army of robots
          building semi-autonomously vs. someone copying and pasting values into a spreadsheet incorrectly on a Tuesday
          afternoon.
        </p>
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={images.spreadsheets} style={{ width: '75%' }} />
        </div>
        <br />
        <br />
        <p>
          However, if the industry has any hope of moving forward, we need to fix some of the simple problems first.
          From my perspective this includes:
        </p>
        <ul>
          <li>
            Using spreadsheets instead of databases (<b>spreadsheet risk</b>) -{' '}
            <a
              href="https://www.researchgate.net/publication/1912352_Spreadsheet_Errors_What_We_Know_What_We_Think_We_Can_Do"
              target="_blank"
              rel="noopener noreferrer"
            >
              research shows
            </a>{' '}
            that on average 86% of business spreadsheets contain errors. In some sectors, this does not matter. However,
            in some sectors (such as Construction) spreadsheet errors can lead to{' '}
            <a
              href="https://www.accountingweb.co.uk/tech/excel/spreadsheet-error-delays-opening-of-ps150m-childrens-hospital"
              target="_blank"
              rel="noopener noreferrer"
            >
              catastrophic mistakes
            </a>
            .
          </li>
          <br />
          <li>
            Copying and pasting data/information to create documents (<b>word risk</b>). It is far too easy to copy and
            paste the wrong excel table into version 100 of the document. Without a single source of truth for
            information, copying and paste static versions will inevitably lead to mistakes.
          </li>
          <br />
          <li>
            Undocumented, chaotic processes (<b>email risk</b>). Processes within Construction are often coordinated via
            email (or more recently Microsoft Teams). However, a lack of a documented, well-structured process means
            that this will change from project to project. Furthermore, businesses risk process knowledge being lost
            when employees move on to different companies.
          </li>
        </ul>
        <p>
          I will go into detail on each of these in other posts but we need to fix these first before thinking about any
          of the other more exciting topics.
        </p>
        <br />
        <h4>Thought #2 - stop making everything so complicated (SMESC)</h4>
        <p>
          The whole sector is drowning in acronyms. BIM. RFI. CDE. IPD....the list goes on. This makes certain topics
          sound very confusing to an outsider but most of the content (not all) is relatively easy to understand.
        </p>
        <p>
          Furthermore, the definition and meaning of certain acronyms are hotly contested (e.g. BIM) which adds another
          layer of complication.
        </p>
        <p>
          Many of the problems being faced by the industry are not unique. Data schemas, collaboration, data quality
          assurance are all problems other industries have faced and overcome.
        </p>
        <p>
          By using a myriad of acronyms it creates complication when in fact people from other industries could easily
          understand and contribute to the discussion.
        </p>
        <br />
        <h4>Thought #3 - open-source is not the answer to everything</h4>
        <p>
          First things first, I am not against open-source software. I use <u>a lot</u> of open-source software and I
          have created several open-source projects in the past.
        </p>
        <p>
          The industry has been locked in and exploited for decades by large software companies in the space. Locking
          customers into proprietary data formats has been a fairly lucrative venture for several companies. Therefore a
          predisposition to like open-source software is only natural.
        </p>
        <p>
          However, making a project open-source does not necessarily increase the likelihood that companies will embrace
          the new technology.
        </p>
        <p>
          Open-source projects severely lack customer support, deployment specialists, and implementation specialists.
          The Construction industry needs help understanding and deploying technology, therefore open source is not a
          &apos;fix all&apos; solution to the problem.
        </p>
        <p>
          One of the primary barriers to any new technology is people trying it and giving feedback. That is what
          startups are craving and in the end will lead to a more productive industry.
        </p>
        <p>
          Paying money for a piece of software will create incentive structures necessary to attract the right talent.
          By paying for software in the space you attract more companies, who in turn hire more people, thereby pushing
          the industry forward at a quicker pace.
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  )
}

export default _20210427
