import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import Routes from 'routes'
import { createBrowserHistory } from 'history'
import 'style.scss'

export const history = createBrowserHistory()

function App() {
  return (
    <Router history={history}>
      <Routes />
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
