import me from 'assets/images/me.jpg'
import spreadsheets from 'assets/images/spreadsheets.jpeg'
import dilbert from 'assets/images/dilbert.jpeg'
import steps from 'assets/images/steps.svg'

export const images = {
  me,
  spreadsheets,
  dilbert,
  steps
}
export const icons = {}
