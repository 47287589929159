import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import Home from 'views/home'
export const HOME = '/'

// Blog Posts
import _20210427 from 'views/blog/20210427'
import _20210505 from 'views/blog/20210505'

export const CONSTRUCTION_OUTSIDER = '/blog/construction-outsider'
export const SPREADSHEET_RISK = '/blog/spreadsheet-risk'

const Routes: React.FC = () => {
  const path = window.location.pathname

  return (
    <Switch>
      <Route path={HOME} exact={true} component={Home} />

      {/* Blog Posts */}
      <Route path={CONSTRUCTION_OUTSIDER} exact={true} component={_20210427} />
      <Route path={SPREADSHEET_RISK} exact={true} component={_20210505} />

      {/* Redirect if a page isn't found */}
      <Route>
        <Redirect to={HOME} />
      </Route>
    </Switch>
  )
}

export default withRouter(Routes)
