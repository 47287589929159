import React, { useEffect } from 'react'
import { HOME } from 'routes'
import { Link } from 'react-router-dom'
import 'views/blog/style.scss'
import 'components/nav/style.scss'
import { images } from 'assets'

const _20210427: React.FC = () => {
  useEffect(() => {
    document.title = 'Blog · Matt Morrison'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="nav">
        <Link to={HOME} style={{ color: 'white', textDecoration: 'none', fontWeight: 600 }}>
          &#8592; Back To Home
        </Link>
      </div>
      <div className="blog-content">
        <h3>Quantifying The Risk of Spreadsheets in Construction</h3>
        <i>
          86% of spreadsheets contain some form of error. Up to 10% of the value of some construction projects is
          destroyed by spreadsheet errors. With the cost of implementing a solution to spot and solve these mistakes
          equating to several thousand pounds per month, the ROI should be clear.
        </i>
        <br />
        <br />
        <br />
        <img src={images.dilbert} style={{ width: '100%' }} />
        <i style={{ fontSize: '85%' }}>Source: Dilbert Strip, January 2016</i>
        <br />
        <br />
        <h4>Spreadsheet Risk #1 - Data Validation and Formula Errors</h4>
        <p>
          Data validation refers to ensuring the user has not made a mistake when inputting the data. For example,
          checking an email address is correctly formatted (x@y.com) or making sure a number lies between a minimum and
          maximum value.
        </p>
        <p>
          Some spreadsheets allow very basic forms of data validation. However, the flexibility of a spreadsheet means
          it is very easy for users to circumvent these measures.
        </p>
        <p>
          Formula errors are also very easy to make in a spreadsheet - for example, forgetting to include a row inside a
          sum calculation.
        </p>

        <div
          style={{
            padding: '20px',
            fontSize: '90%',
            width: '100%',
            borderRadius: '3px',
            fontWeight: 600,
            backgroundColor: '#2d2d2d',
            color: '#cccccc',
            boxSizing: 'border-box'
          }}
        >
          <p>Example - Madison High School</p>
          <p>
            Portland Public Schools awarded a contract to the wrong firm in a{' '}
            <span style={{ color: '#c997cb' }}>$146m redesign of Madison High School</span>. This happened as a result
            of a formula error evaluating bids from the contractors.
          </p>
        </div>

        <br />
        <h4>Spreadsheet Risk #2 - Data Verification</h4>
        <p>
          Data verification ensures that the inputted information is accurate and complies with specifications. This is
          typically done by humans and involves checking the entered information against rules held in a Word Document
          or PDF.
        </p>
        <p>
          In a construction project with thousands of verification checks, it is very easy for erroneous and
          non-conforming data to make it through to final versions of spreadsheets. Furthermore,{' '}
          <b>
            <u>if</u>
          </b>{' '}
          data verification is undertaken on the project, it is extremely costly to pay employees to check data in this
          way.
        </p>

        <div
          style={{
            padding: '20px',
            fontSize: '90%',
            width: '100%',
            borderRadius: '3px',
            fontWeight: 600,
            backgroundColor: '#2d2d2d',
            color: '#cccccc',
            boxSizing: 'border-box'
          }}
        >
          <p>Example - NHS Lothian Children&apos;s Hospital</p>
          <p>
            This <span style={{ color: '#c997cb' }}>£150m project</span> was delayed (at a total cost of{' '}
            <span style={{ color: '#c997cb' }}>~£28m</span>) due to a{' '}
            <span style={{ color: '#7ec699' }}>human error in the environmental matrix</span> spreadsheet related to
            ventilation criteria in critical care rooms.
          </p>
          <p>
            The values in this spreadsheet were incorrectly copied and no thorough verification process took place to
            check values against a specification document.
          </p>
        </div>

        <br />
        <h4>Spreadsheet Risk #3 - Version Control</h4>
        <p>
          Version control is notoriously difficult in spreadsheets. While this has improved over years (with files being
          moved to Google Drive, BIM360, etc.) users are still too often attaching spreadsheets to emails.
        </p>
        <p>
          This is often a necessity as it is difficult to share parts of a spreadsheet with different users and
          therefore it requires the master user to copy and paste portions of the spreadsheet and aggregate them at the
          end.
        </p>

        <br />
        <h4>Spreadsheet Risk #4 - Audit Trail</h4>
        <p>
          Spreadsheets lack an in-built audit trail of who changed what and when. We are becoming all too familiar with
          the negative impact of a lack of transparency around which firms contributed which data during a project.
        </p>

        <br />
        <h4>Quantifying Spreadsheet Risk</h4>
        <div
          style={{
            padding: '20px',
            fontSize: '90%',
            width: '100%',
            borderRadius: '3px',
            fontWeight: 600,
            backgroundColor: '#2d2d2d',
            color: '#cccccc',
            boxSizing: 'border-box'
          }}
        >
          <i>Value of risk = up to 10% of project value</i>
        </div>
        <br />
        <p>
          <a
            href="https://www.researchgate.net/publication/1912352_Spreadsheet_Errors_What_We_Know_What_We_Think_We_Can_Do"
            target="_blank"
            rel="noopener noreferrer"
          >
            Research shows
          </a>{' '}
          that 86% of spreadsheets contain some form of error. Quantifying the risk associated with these errors is very
          difficult. Small errors can accumulate over time, having multiple costs during the lifetime of a project. In a
          worst-case scenario, a single spreadsheet error can cause the delay of an entire project.
        </p>
        <p>
          To understand the risk associated with spreadsheets we have to look at it from a worst-case scenario
          perspective. I have analysed several construction projects where a simple spreadsheet error occurred - they
          show that a single spreadsheet error can cause up to 10% of the project value to be destroyed.
        </p>
        <br />
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrmYeqd5MDBV1hqC?backgroundColor=orange&viewControls=on"
          frameBorder="0"
          width="100%"
          height="533"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
        ></iframe>
        <br />
        <br />
        <p>
          This is just the direct cost of such an error. The projects listed in the database above also had
          hidden/indirect costs associated with the errors, including consultancy fees, audit fees, and reputational
          damage.
        </p>

        <br />
        <br />
        <h4>Cost of Mitigating Spreadsheet Risk</h4>
        <div
          style={{
            padding: '20px',
            fontSize: '90%',
            width: '100%',
            borderRadius: '3px',
            fontWeight: 600,
            backgroundColor: '#2d2d2d',
            color: '#cccccc',
            boxSizing: 'border-box'
          }}
        >
          <i>Cost of mitigating risk = several thousand pounds per month + 3-6 weeks implementation</i>
        </div>
        <br />
        <p>
          The cost of mitigating the spreadsheet risks outlined above is very small compared to the potential cost
          incurred from a spreadsheet error.
        </p>
        <br />
        <img src={images.steps} style={{ width: '100%' }} />
        <br />
        <br />
        <p>
          The <b>first step</b> involves migrating your spreadsheet data over to a web-based database provider. There
          are many examples of this including{' '}
          <a href="https://morta.io" target="_blank" rel="noopener noreferrer">
            Morta
          </a>{' '}
          and{' '}
          <a href="https://airtable.com/" target="_blank" rel="noopener noreferrer">
            Airtable
          </a>
          . This requires some initial thinking about how to organise and map your data from a spreadsheet into a
          database but should only take one to two weeks to implement.
        </p>
        <p>
          The <b>second step</b> is to create &apos;smart documents&apos; that contain the information requirements that
          will be used for data verification measures. Again,{' '}
          <a href="https://morta.io" target="_blank" rel="noopener noreferrer">
            Morta
          </a>{' '}
          allows you to do this using digital processes but some innovative thinking could be used to create rules
          inside of a web-based document editor such as Google Docs.
        </p>
        <p>
          The <b>third step</b> is to run these data verification steps every time the database is updated. This will
          ensure that whenever data is used in your project it conforms to all the requirements. This can be done by a
          developer or programmer creating a simple script in the language of their choice.
        </p>
        <br />
        <h4>A High ROI - Why Does The Industry Not Move in this Direction?</h4>
        <p>
          People have mixed opinions on this question. It seems to be a combination of not understanding the true cost
          of spreadsheet risk and being missold/confused around the steps needed to mitigate these risks. Yet, taking
          this seriously could save your project millions of pounds.
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  )
}

export default _20210427
