import { icons, images } from 'assets'
import React, { useEffect } from 'react'
import { CONSTRUCTION_OUTSIDER, SPREADSHEET_RISK } from 'routes'
import { Link } from 'react-router-dom'
import 'views/home/style.scss'
import 'components/nav/style.scss'

const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'Home · Matt Morrison'
  }, [])
  return (
    <div>
      <div className="nav" />
      <div className="home-container">
        <div className="about-me">
          <img className="picture" src={images.me} />
          <p>
            Hi - my name is Matt. I live in London and currently building cool things at{' '}
            <a href="https://morta.io" target="_blank" rel="noopener noreferrer">
              Morta
            </a>
            . I am interested in all things construction, blockchain and economics.
          </p>
        </div>
        <br />
        <br />
        <h3>Who Am I?</h3>
        <p>
          I graduated in Economics in 2012 and immediately went into the Finance sector. After a few years I decided to
          pursue my interest in programming and completed a Computer Science degree at Imperial College London in 2017.
        </p>
        <p>
          I spent most of my time at Imperial working on Blockchain applications (specifically using Ethereum). I worked
          on a diverse range of smart contracts, including{' '}
          <a
            href="https://github.com/matthewsmorrison/Insurance-With-Smart-Contracts"
            target="_blank"
            rel="noopener noreferrer"
          >
            Insurance
          </a>
          ,{' '}
          <a
            href="https://github.com/matthewsmorrison/Lending-With-Smart-Contracts"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lending,
          </a>{' '}
          and{' '}
          <a
            href="https://www.imperial.ac.uk/media/imperial-college/faculty-of-engineering/computing/public/1718-pg-projects/MorrisonM-A-Government-Backed-Cryptocurrency.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Universal Basic Income
          </a>
          . I am now CTO at Morta working on the next-generation construction spreadsheet.{' '}
        </p>{' '}
        <br />
        <h3>Where To Find Me</h3>
        <p>
          You can find me on{' '}
          <a href="https://github.com/matthewsmorrison" target="_blank" rel="noopener noreferrer">
            GitHub
          </a>
          ,{' '}
          <a href="https://twitter.com/6d617474686577" target="_blank" rel="noopener noreferrer">
            Twitter
          </a>{' '}
          or{' '}
          <a href="https://uk.linkedin.com/in/matthewseamusmorrison" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
          .
        </p>
        <br />
        <h3>Construction Blog Posts</h3>
        <ul>
          <li>
            <Link to={SPREADSHEET_RISK}>Quantifying The Risk of Spreadsheets in Construction</Link> (Published 6th May
            2021)
          </li>
          <li>
            <Link to={CONSTRUCTION_OUTSIDER}>The Construction Industry - An Outsider Perspective</Link> (Published 29th
            April 2021)
          </li>
        </ul>
        <br />
        <h3>Technical Blog Posts</h3>
        <p>Coming Soon</p>
        <br />
        <h3>Open Source Projects</h3>
        <ul>
          <li>
            <a href="https://firedashboard.app/" target="_blank" rel="noopener noreferrer">
              Fire Dashboard
            </a>{' '}
            (
            <a href="https://github.com/matthewsmorrison/fire-dashboard" target="_blank" rel="noopener noreferrer">
              Source Code
            </a>
            )
          </li>

          <li>
            <a
              href="https://www.imperial.ac.uk/media/imperial-college/faculty-of-engineering/computing/public/1718-pg-projects/MorrisonM-A-Government-Backed-Cryptocurrency.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cryptocurrency Universal Basic Income
            </a>{' '}
            (
            <a href="https://github.com/matthewsmorrison/Worgl-Coin" target="_blank" rel="noopener noreferrer">
              Source Code
            </a>
            )
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Home
